export type Callback = (payload: any) => void;

export type Unsubscribe = (id: string, url: string) => void;

/**
 * Subscriber object config
 * @param url (required) - contains the socket target end-point to connect to it.
 * This parameter will be used to index the active Websockets subscriptions,
 * so make sure that the url is properly sanitized to guarantee the consistency of them
 * and avoid duplicates. If there is an existing connection to the same `url` within the service,
 * it will re-use it. Otherwise, it will create a new connection.
 *  sending it through an event.
 * @param messages (optional) - Array containing the different messages
 *  subscribed to trigger the callback if they are received in a socket onmessage
 * @param onOpen (optional) - will be called when the socket connection has been opened succesfully.
 *  If there is an existing subscription to the `url` and the connection is already socket,
 *  it will be called without an event as a hand-shake.
 * @param onClose (optional) -  will be called when the Websocket connection has been closed
 *  emiting a close event.
 */
export type SubscribeConfig = {
  url: string,
  messages?: string[],
  onOpen?: (event: Event) => void;
  onClose?: (event: CloseEvent) => void;
}

export default class Subscriber {
  public id: string;

  public config: SubscribeConfig;

  public callback: Callback;

  constructor(
    id: string,
    config: SubscribeConfig,
    callback: Callback,
  ) {
    this.id = id;
    this.config = config;
    this.callback = callback;
  }
}
