export const NO_RECONNECT_ERROR_CODES = [
  // Normal Closure
  1000,
  // No Status Received
  1005,
];

export const DEFAULT_RECONNECT_TIMEOUT_MS = 2000;

export const HEARTBEAT_RESPONSE = 'HEARTBEAT_RESPONSE';
export const HEARTBEAT_REQUEST = 'HEARTBEAT_REQUEST';
export const HEARTBEAT_TIMEOUT = 20000;

export const KEEP_ALIVE_URLS = ['/service/ws-offline', '/service/ws/'];
