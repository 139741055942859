import {
  DEFAULT_RECONNECT_TIMEOUT_MS, NO_RECONNECT_ERROR_CODES,
  HEARTBEAT_RESPONSE, HEARTBEAT_REQUEST, HEARTBEAT_TIMEOUT,
  KEEP_ALIVE_URLS,
} from './constants';
import { logger } from './services';
import Subscriber from './Subscriber';

class WebSocketClient {
  public subscriptions: Map<string, Subscriber[]> = new Map();

  public webSockets: Map<string, WebSocket> = new Map();

  private heartBeats: Map<string, boolean> = new Map();

  private heartBeatsTimeouts: Map<string, NodeJS.Timeout> = new Map();

  private createSocket(url: string) {
    const socket = new WebSocket(url);

    socket.onopen = (event: Event) => {
      logger.debug('Connection opened', { url, event });

      this.subscriptions.get(url)?.forEach((s) => {
        if (s.config.onOpen) {
          s.config.onOpen(event);
        }
      });
      this.startHeartBeat(url);
    };

    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      this.subscriptions.get(url)?.forEach((s) => {
        if (s.config.messages?.some((message) => event.data?.includes(message))) {
          s.callback(data);
        }
      });

      if (data.type === HEARTBEAT_RESPONSE) {
        this.heartBeats.set(url, true);
      }
    };

    socket.onclose = (event: CloseEvent) => {
      logger.debug('Connection closed', { url, event });

      const socketInUse = this.webSockets.get(url);
      // should not call onclose if there is websocket with same url in open state
      if (!socketInUse || socketInUse.readyState === WebSocket.CLOSED) {
        this.subscriptions.get(url)?.forEach((s) => {
          if (s.config.onClose) {
            s.config.onClose(event);
          }
        });
      }

      if (!NO_RECONNECT_ERROR_CODES.includes(event.code)) {
        const reconnectTimeout = (window.args?.timeouts?.websocketErrorTimeout
          || DEFAULT_RECONNECT_TIMEOUT_MS) + ((Math.floor(Math.random() * 5) + 1) * 1000);

        setTimeout(() => {
          logger.debug('Reconnecting to websocket', { url });
          this.recreateSocket(url);
        }, reconnectTimeout);
      }
    };

    socket.onerror = (error: Event) => {
      logger.error('Failed to create WebSocket connection', { url, error });
    };

    return socket;
  }

  public setWebSocket(url: string) {
    if (!this.webSockets.has(url)) {
      const socket = this.createSocket(url);
      this.webSockets.set(url, socket);
      logger.debug('Set and created new websocket', { url });
    }
  }

  public deleteWebSocket(url: string): void {
    const socket = this.webSockets.get(url);

    if (socket) {
      if (socket.readyState < WebSocket.CLOSING) {
        socket.close(1000, 'Last subscriber unmounted');
      }

      this.webSockets.delete(url);
      this.heartBeats.delete(url);
      const timeoutId = this.heartBeatsTimeouts.get(url);
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
        this.heartBeatsTimeouts.delete(url);
      }
    }
  }

  public recreateSocket(url: string) {
    logger.debug('Try to delete/recreate websocket, only recreate if subscriber exists', { url });

    this.deleteWebSocket(url);
    logger.debug('Deleted websocket', { url });

    // Reason for this check
    // For example, wss url A is in the reconnect timeout, say after 5s, it will reconnect
    // in the meanwhile, client did a unsubscribe on url A, and it get deleted from subscriptions
    // After 5s, timeout ends, here will try to recreate wss with url A
    // need to check if url A still have any subscriptions, skip recreation if no subscription
    const existSubscriber = this.subscriptions.get(url);
    if (existSubscriber) {
      logger.debug('Subscriber exists, recreate websocket', { url });
      setTimeout(() => this.setWebSocket(url), 100);
    }
  }

  /**
   * PRM-3241, WAL-4188  Due to Apple devices having a bug
   * where it does not close websocket in case of network loss/ip change:
   * we ping server and if we do not get response in reasonable time,
   * we close the websocket (note that from debugger perspective, this is still open)
   * and open a new one
  */
  private startHeartBeat(url: string) {
    // !TODO only trigger heartbeat on certain urls
    // !TODO as not all of the websockets support HEARTBEAT_RESPONSE ATM (i.e: the sportsbook one)
    // !TODO ticket https://rushstreetgaming.atlassian.net/browse/COMT-192
    const keepAliveUrls = [...KEEP_ALIVE_URLS, window.args?.wssUrl, window.args?.wssUnauthUrl];
    const shouldStartBeat = keepAliveUrls.some((keepAliveUrl) => url.includes(keepAliveUrl));
    if (shouldStartBeat && (RSIUtils.detector.isIOS || RSIUtils.detector.isMac)) {
      this.heartBeats.set(url, true);
      this.keepAlive(url);
    }
  }

  private keepAlive(url: string) {
    if (!this.heartBeats.get(url)) {
      this.recreateSocket(url);
      return;
    }

    const socket = this.webSockets.get(url);
    if (!socket) {
      logger.error('keepAlive is triggered but socket is missing', { url });
      return;
    }

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ type: HEARTBEAT_REQUEST }));
      this.heartBeats.set(url, false);
      const timeoutId = setTimeout(() => {
        this.keepAlive(url);
      }, HEARTBEAT_TIMEOUT);
      this.heartBeatsTimeouts.set(url, timeoutId);
    } else {
      logger.error('keepAlive is triggered but socket is not in open state', { url });
    }
  }
}

export default WebSocketClient;
