import { v4 as uuidv4 } from 'uuid';
import { logger } from './services';
import Subscriber, { Callback, SubscribeConfig } from './Subscriber';
import WebSocketClient from './WebSocketClient';

/**
 * Service that handles Websocket connections allowing multiple subscriptions
 *  to single websocket instances. I also handles all of the socket callbacks,
 * filtering the messages according to each subscriber config.
 * If the websocket is closed by an error (not manually),
 * the service also will handle the re-connection.
 * When we receive or send messages, the RSIWebSocket will additionally parse or serialize them,
 * so we just need to handle objects in the callbacks/sends
 */
class RSIWebSocket extends WebSocketClient {
  constructor() {
    super();
    RSIEventBus.subscribe(
      RSIEventBus.eventTypes.VISIBILITY_CHANGE,
      (isVisible: boolean) => {
        if (isVisible) {
          new Map(this.webSockets).forEach((_, url) => {
            this.recreateSocket(url);
          });
        }
      },
    );
    logger.trace('Module mounted');
  }

  /**
  * In order to unsubscribe, use the method returned within the subscriber object:
  *
  * Example:
  * ```typescript
  * const socket = RSIWebSocket.subscribe({
  *     url: 'wss://api-dev.rushstreetinteractive.cm/service/ws/',
  *     onOpen: () => dispatch(setWssAvailable(true)),
  *     onClose: () => dispatch(setWssAvailable(false)),
  * }, onMessageCallback);
  *
  * socket.unsubscribe();
  * ```
  * When the unsubscribe method is triggered it will remove the subscriber from the connection.
  * If it is the last subscriber remaning for the WebSocket connection,
  *  it will additionally close and remove the WebSocket connection.
  */
  private unsubscribe(id: string, url: string): void {
    const subscribers = this.subscriptions.get(url) || [];

    const unsubable = subscribers.some((sub) => sub.id === id);

    if (!unsubable) {
      logger.warn('Tried to remove a subscriber that is not in subscription list, skipped', { id, url });
      return;
    }

    if (subscribers.length > 1) {
      this.subscriptions.set(
        url,
        subscribers.filter((subscriber) => subscriber.id !== id),
      );

      logger.trace('Removed subscriber', { id, url });
    } else {
      this.subscriptions.delete(url);

      this.deleteWebSocket(url);

      logger.trace('Removed subscriber with subscription', { id, url });
    }
  }

  /**
  * In order to send messages through the socket client,
  * use the `send` method returned within the subscriber object:
  *
  * Example:
  * ```
  * const socket = RSIWebSocket.subscribe({
  *     url: 'wss://api-dev.rushstreetinteractive.cm/service/ws/',
  *     onOpen: () => dispatch(setWssAvailable(true)),
  *     onClose: () => dispatch(setWssAvailable(false)),
  * }, onMessageCallback);
  *
  * socket.send({
  *     messageType: 'EVENT_SUBSCRIBE',
  *     id: 3333,
  * });
  * ```
  * It's important to consider that if the socket connection gets closed by an error and gets
  * re-opened by the service, the new instance will be clean and
  * it will ignore all of the messages that were sent to the previous one.
  * Therefore, in case of need to subscribe to certain content using `send`,
  * we should also add this calls to the `onOpen` listener
  * to guarantee the correct flow of the messages.

  * Example:
  * ```
  * const subscribeMessage = {
  *     messageType: 'EVENT_SUBSCRIBE',
  *     id: 3333,
  * }
  *
  * const socket = RSIWebSocket.subscribe({
  *     url: 'wss://api-dev.rushstreetinteractive.cm/service/ws/',
  *     messages: ['BET_OFFER_UPDATE'].
  *     onOpen: () => {
  *         dispatch(setWssAvailable(true));
  *         socket.send(subscribeMessage)
  *     }
  *     onClose: () => dispatch(setWssAvailable(false)),
  * }, onMessageCallback);
  * socket.send(subscribeMessage);
  * ```
  */
  private send(url: string, payload: any) {
    const socket = this.webSockets.get(url);
    if (!socket) return;

    const sendMessage = () => socket.send(JSON.stringify(payload));

    if (socket.readyState === socket.OPEN) {
      sendMessage();
    } else {
      socket.addEventListener('open', sendMessage);
    }
  }

  /**
  * @param config Subscriber options
  * @param callback Callback function called when received new messages related to the subscription
  * @returns An object containing the `unsubscribe` method and the websocket `send` method
  *
  * Example:
  * ```typescript
  * RSIWebSocket.subscribe({
  *     url: 'wss://api-dev.rushstreetinteractive.cm/service/ws/',
  *     onOpen: () => dispatch(setWssAvailable(true)),
  *     onClose: () => dispatch(setWssAvailable(false)),
  * }, onMessageCallback);
  * ```
  */
  public subscribe(config: SubscribeConfig, callback: Callback) {
    const id = uuidv4();

    const subscriber = new Subscriber(
      id,
      config,
      callback,
    );

    this.subscriptions.set(config.url, [
      ...(this.subscriptions.get(config.url) || []),
      subscriber,
    ]);

    logger.trace('Registered new subscriber', { subscriber });

    this.setWebSocket(config.url);

    return {
      unsubscribe: () => this.unsubscribe(id, config.url),
      send: (payload: any) => this.send(config.url, payload),
    };
  }
}

export default RSIWebSocket;
